import { useIdleTimer } from "react-idle-timer";

const IdleLogout = ({ keycloak }) => {
  const timeout = 15 * 60 * 1000; 

  const handleOnIdle = () => {
    
    keycloak.logout({
        redirectUri: process.env.REACT_APP_AUTH_LOGOUTURL, 
      });
      keycloak.clearToken()
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return null;
};

export default IdleLogout;

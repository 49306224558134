// Callback.js
import React, { useEffect, useState, useCallback } from 'react';
import { setAuthToken } from "../Service/api"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountActions from "../Redux/actions/account-actions";
import DefMembershipActions from "../Redux/actions/defMembership-actions";
import ProfileActions from "../Redux/actions/profile-actions";
import Loading from "../Components/Loading";
import { useKeycloak } from "@react-keycloak/web";

const ImplicitCallback = () => {
  const [loading, setLoading] = useState(true);
  const { keycloak } = useKeycloak();
 

  const [emailStatus, setEmailStatus] = useState(false)
  const navigate = useNavigate();

  //Redux State from Store
  const account = useSelector((state) => state.account.account);
  //const errorAccount = useSelector((state) => state.account.error)
  const fetchingAccount = useSelector((state) => state.account.fetching);
  const defMembership = useSelector((state) => state.defMembership.defMembership);
  const errorDefMembership = useSelector((state) => state.defMembership.error);
  const fetchingDefMembership = useSelector((state) => state.defMembership.fetching);
  const profile = useSelector((state) => state.profile.profile);
  const fetchingProfile = useSelector((state) => state.profile.fetching);

  //Redux Dispatch Actions
  const dispatch = useDispatch();
  const getAccount = useCallback(() => { dispatch(AccountActions.accountRequest()); }, [dispatch]);
  const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
  const getProfile = useCallback(() => dispatch(ProfileActions.profileRequest()), [dispatch]);

  const handleToken = useCallback(async () => {
    try {
      await keycloak.updateToken(60)
        .then(() => {
          const token = keycloak.token;
          setAuthToken(token);
        })
        .then(() => {
          getProfile();
          getAccount()
          getMembership()
        })
        .then(() => {
          if(!fetchingDefMembership){
            setLoading(false);
          }
            
        });
    } catch (e) {
      keycloak.clearToken()
      keycloak.logout({
        redirectUri: process.env.REACT_APP_AUTH_LOGOUTURL, // Redirect to localhost:3000 after logout
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleToken();
  }, [handleToken]);


  useEffect(() => {
    if(!loading){
    
    if (account  && !fetchingDefMembership && !fetchingAccount && !fetchingProfile) {
      if(!profile?.emailVerified){
          navigate('/verification', { replace: true });
      }
     else if (defMembership && !errorDefMembership) {
          console.log("HAS DEFAULT MEMBERSHIP");
          navigate("/home", { replace: true });
      } else if (!defMembership && errorDefMembership.status === 404) {
          console.log("HAS NO DEFAULT MEMBERSHIP");
          navigate('/configure', { replace: true });
      } else if (!defMembership && errorDefMembership) {
          navigate('/denied', { replace: true });
      }
  }
}
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, defMembership, account, profile, fetchingDefMembership, fetchingAccount, fetchingProfile]);

  useEffect(() => {
    if(profile?.emailVerified){
        setEmailStatus(true)
    }
},[profile, emailStatus])

return (loading || fetchingProfile || fetchingAccount || fetchingDefMembership) ? <div style={{height: "100vh"}}><Loading/></div> : null;
};

export default ImplicitCallback;

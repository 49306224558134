import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'primereact/button';
import { removeAuthToken } from "../Service/api";
import DefMembershipActions from "../Redux/actions/defMembership-actions";
import "../Styles/components/ErrorFallback.css";
import Logo from "../assets/images/Reveknew_Logo_Colour_and_Black.png";
import { useKeycloak } from "@react-keycloak/web";

export function ErrorFallback({ error }) {
    const { t } = useTranslation();

    const defMembership = useSelector((state) => state.defMembership.defMembership);

    const dispatch = useDispatch();
    const getMembership = useCallback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToDashboard = () => {
        if (role) {
            window.location.replace('/home')
        } else {
            window.location.replace('/subscribers')
        }
    };

    const { keycloak } = useKeycloak();

    const logoutFn = () => {
        console.log('3');
        
        localStorage.setItem('dialogClosed', JSON.stringify(false));
        removeAuthToken();
        keycloak.logout({
			redirectUri: process.env.REACT_APP_AUTH_LOGOUTURL,
		  });
          keycloak.clearToken()
          
        sessionStorage.setItem('crumbs', [])
    }

    const role = ["VIEWER", "MANAGER"].includes(defMembership?.role)


    return (
        <div className="container">
            <div className="console">
                <img src={Logo} alt="Reveknew-Logo" width={150} />
                <h4 className='text-tight'>{t("common.console_name")}</h4>
            </div>
            <div className="content">
                <img src='error_fallback_revkn.png' alt={""} height={450} />
                <div className=""></div>
                <h1 className="text-main" >{t("common.error_fallback_text")}</h1>
                <div className="">
                    <h2 className="text-tighter subtext">{t("common.error_fallback_subtext")}</h2>
                    <h3 className="bottom-space subtext">{t("common.error_fallback_subtext2")}</h3>
                </div>
                <div>
                    <Button
                        label={role ? t("common.home") : "Go to subscribers"} className="p-button-raised p-button-rounded"
                        icon='pi pi-home' style={{ backgroundColor: '#3E82FE', border: "none" }}
                        onClick={goToDashboard}
                    />

                    <Button label={t("nav.logout")} className="p-button-raised p-button-rounded"
                        style={{ backgroundColor: '#D0021B', marginLeft: '1.5rem' }} onClick={logoutFn} />
                </div>
            </div>
        </div>
    )
}
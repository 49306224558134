import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
//Redux actions import
import PhoneVerificationActions from "../../../Redux/transition/phone-verification/phone-verification.reducer";
import SubscriberActions from "../../../Redux/transition/subscriber/subscriber.reducer";
import SelfSignupActions from "../../../Redux/transition/self-signup/self-signup.reducer";
import BusinessActions from "../../../Redux/actions/business-actions"
//primereact imports
import { InputText } from "primereact/inputtext";
// import { InputNumber } from 'primereact/inputnumber';
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { TreeSelect } from 'primereact/treeselect';
import { useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { classNames } from "primereact/utils";
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
//Other imports
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from 'libphonenumber-js';
import "../../../Styles/components/sti.css"
import "../../../Styles/pages/inviteSubscriber.scss"
import Loading from "../../../Components/Loading";
import "../../../Styles/pages/invitation.scss";
import { InputSwitch } from "primereact/inputswitch";
import OnboardingNotification from "../../../Components/OnboardingNotification"

const InviteSubscriber = (props) => {
	//redux state and dispatch to props + props from parent component
	const { onHide, initialTier, phoneNo, toggleVisible, header, callbackFunctions } = props
	const defMembership = useSelector((state) => state.defMembership.defMembership)
	const business = useSelector((state) => state.businesses.business)
	const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive)
	const fetchingExclusivity = useSelector((state) => state.subscribers.fetchingExclusivity)
	const exclusivity = useSelector((state) => state.subscribers.exclusivity)
	const errorExclusivity = useSelector((state) => state.subscribers.errorExclusivity)
	const generating = useSelector((state) => state.phoneVerifications.generating)
	const phoneVerification = useSelector((state) => state.phoneVerifications.phoneVerification)
	const error = useSelector((state) => state.phoneVerifications.errorGenerating)
	const paymentCredentials = useSelector((state) => state.payswitch.paymentCredentials);
	const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
	const verificationStatus = useSelector((state) => state.documents.verifStatus);

	const dispatch = useDispatch()
	const checkExclusivity = useCallback((newPlanId, msisdn) => dispatch(SubscriberActions.subscriberExclusivityRequest(newPlanId, msisdn)), [dispatch])
	const getSelfSignups = useCallback((businessId, options) => dispatch(SelfSignupActions.selfSignupAllRequest(businessId, options)), [dispatch])
	const generateCode = useCallback((generationRequest) => dispatch(PhoneVerificationActions.generateCodeRequest(generationRequest)), [dispatch])
	const getBusiness = useCallback((businessId) => { dispatch(BusinessActions.businessRequest(businessId)) }, [dispatch])

	const { t } = useTranslation("common");
	const navigate = useNavigate();
	const toast = useRef("");
	const toastConfirm = useRef("");
	const [sending, setSending] = useState(false);
	const [displayBasic, setDisplayBasic] = useState(false);
	const [toggleDeductionNumber, setToggleDeductionNumber] = useState(false)
	const [checked, setChecked] = useState(true);
	const [openNotification, setOpenNotification] = useState(false);
	const [closeErrorDialog, setCloseErrorDialog] = useState(false)

	const [state, setState] = useState({
		selectedNodeKey: "",
		inviteData: "",
		checking: false,
		checkingExclusive: false,
		success: false,
		requesting: false,
		input: "",
		visible: false,
		hide: true,
		defMembership: null,
		confirmVisible: false,
		desireVisible: false,
		selectOptions: [],
		sort: 'requestedOn,desc',
		size: 10,
		generating: false,
		plan_tier: " ",
		newData_group: null,
		exclusivity: null,
		key: 0 - 1,
		exists: false,
		existVisible: false,
		phoneNo: ""
	});

	const paymentSaved = paymentCredentials?.value

	useEffect(() => {
		if (!business) {
			getBusiness(defMembership?.business?.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [business])

	useEffect(() => {
		let loading = fetchingVerifStatus;
		setState((state) => { return { ...state, fetchingVerifRecord: loading }; });
		if (business) { setState((state) => ({ ...state, business: business })); }
		if (verificationStatus) { setState((state) => { return { ...state, verifStatus: verificationStatus }; }) }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchingVerifStatus, business, verificationStatus]);

	useEffect(() => {
		const nonArchivedGroups = planGroupsGraphedActive?.groups?.map((group) => {
			const nonArchivedPlans = group.plans.filter((plan) => !plan.status.includes("ARCHIVED"));
			return {
				...group,
				plans: nonArchivedPlans,
			};
		})


		if (nonArchivedGroups) {
			let planGroupAssigned = [];
			nonArchivedGroups.map((item, ind) => {
				const {
					group: { id },
					group,
					plans,
				} = item;

				planGroupAssigned.push({
					key: id,
					label: group.name,
					selectable: false,
					data: group,
					children:
						plans === null
							? []
							: plans.map((item, idx) => ({
								key: `${ind}-${idx}`,
								label: item.name,
								data: item,
							})),
				});
				setState((prev) => {
					return {
						...prev,
						selectOptions: planGroupAssigned
					}
				})
				return null;
			});
		}
	}, [planGroupsGraphedActive])

	useEffect(() => {
		if (state.checking && !fetchingExclusivity && exclusivity) {
			if (exclusivity.failed) {
				if (exclusivity.desired.id === exclusivity.existing.id) {
					setDisplayBasic(true)
					setState({ ...state, checking: false, exclusivity, })
				}
				else {
					setState({ ...state, checking: false, exclusivity, desireVisible: true })
				}
			}
		}
		if (state.checking && !fetchingExclusivity && exclusivity) {
			if (!exclusivity.failed) {
				setState({ ...state, checking: false, confirmVisible: true, success: false, requesting: true, generating: true })
			}
		}
		if (state.checking && !fetchingExclusivity && errorExclusivity) {
			showError()
		}
		if (sending && !generating && error) {
			if (error.message === t("invitation.already_sub-error-message")) {
				setState((state) => ({ ...state, existVisible: true }))
				resetForm()
				setCloseErrorDialog(true)
			} else {
				showError(t("invitation.invitation_error"))
				setSending(false)
			}
		}
		if (sending && !generating && phoneVerification) {
			showSuccess()
		}
		if (initialTier) {
			Object.assign(initialTier, { label: initialTier.data.name, key: `${0}-${0}` })
			setState((state) => ({ ...state, selectedNodeKey: initialTier }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.requesting, sending, generating, error, state.checking, exclusivity, initialTier, state.confirmVisible, phoneVerification, state.generating, errorExclusivity, fetchingExclusivity])

	useEffect(() => {
		if (phoneNo) {
			formik.setFieldValue("number", phoneNo);
		}
	},// eslint-disable-next-line react-hooks/exhaustive-deps
		[phoneNo])

	const showSuccess = () => {
		toast.current.show({
			severity: 'success', summary: t("invitation.success_header"),
			detail: t("invitation.success_detail"), life: 3000, id: 5
		});
	}

	const showError = (data) => {
		toast.current.show({ severity: 'error', summary: t("invitation.invitation_error_header"), detail: data, life: 3000, id: 2 });
	}

	const closeDialog = () => {
		setCloseErrorDialog(false)
		setSending(false)
	}

	const resetForm = () => {
		formik.resetForm();
		setState((state) => { return { ...state, checking: false, requesting: false, selectedNodeKey: null } })
		setChecked(true)
	}

	const acceptInvite = () => {
		toogleInviteSubscriber()
		generateCode(state.inviteData);
		setSending(true)
		setState((state) => ({ ...state, generating: true, success: false, requesting: false, }));
	}
	const rejectInvite = () => {
		setState({ ...state, checking: false, success: false, requesting: false, confirmVisible: false, desireVisible: !state.desireVisible })
	}
	const hideInvite = () => {
		toogleConfirmInviteSubscriber()
		setState({ ...state, checking: false, success: false, requesting: false, confirmVisible: false })
	}
	const hideExclusivity = () => {
		setState({ ...state, checking: false, success: false, requesting: false, desireVisible: !state.desireVisible })
		setChecked(true)
	}

	const toogleInviteSubscriber = () => {
		if (fetchingVerifStatus) {
			return <Loading />
		}
		else {
			if (business.verified && paymentSaved) {
				setState(state => { return { ...state, visible: !state.visible } })
			}
			else if (!business.verified) {
				if (verificationStatus && !fetchingVerifStatus) {
					return toggleVerifPendingDialogue();
				}
				if (!verificationStatus && !fetchingVerifStatus) {
					switch (defMembership.role) {
						case "OWNER":
							return <OnboardingNotification visible={true} onHide={toggleSetOpenDialog} />
						default:
							toggleNotOwnerDialogue();
					}
				}
			}
			else if (business.verified && !paymentSaved) {

				switch (defMembership.role) {
					case "OWNER":
						// history.push({ pathname: "/settings", state: { 'index': 1 } });
						navigate({ pathname: "/settings", state: { 'index': 1 } });
						break;
					default:
						toggleNotSavedDialogue();
				}
			}
		}
	}

	useEffect(() => {
		if (toggleVisible) {
			toogleInviteSubscriber()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleVisible])

	const formik = useFormik({
		initialValues: {
			plan: "",
			plan_tier: initialTier ? initialTier.data : "",
			number: phoneNo ? phoneNo : "",
			startDate: null,
			amount: null,
			deductions: ""
		},

		validate: (data) => {
			let errors = {};
			if (!initialTier && !data.plan_tier) {
				errors.plan_tier = t("invitation.plan_error");
			}
			if ((policyType === "SUBSCRIPTION" && !data.amount)) {
				errors.amount = t("invitation.amount_error")
			}
			if (!checked && !data.startDate) {
				errors.startDate = t("invitation.date_error")
			}
			if (!data.number) {
				errors.number = t("invitation.number_error")
			}
			if (toggleDeductionNumber === true && data.deductions === null) {
				errors.deductions = t("invitation.deductionNumber_error")
			}
			if (toggleDeductionNumber === true && data.deductions < 0) {
				errors.deductions = t("invitation.enter_positive_deductionNumber")
			} else {
				const check = isValidPhoneNumber(data.number, defMembership.business.country.code);
				if (!check) {
					errors.number = t("invitation.invalid_number")
				}
			}
			return errors;
		},

		onSubmit: (data) => {
			const id = initialTier ? initialTier.data.id : data.plan_tier.id
			const name = initialTier ? initialTier.data.name : data.plan_tier.name
			const business = initialTier ? initialTier.data.business : data.plan_tier.business
			const groupId = initialTier ? initialTier.data.groupId : data.plan_tier.groupId
			const deductions = initialTier ? initialTier.data.deductions : ""
			const requestedOn = new Date();
			const msisdn = phoneNo ? defMembership.business.country.phoneCode + phoneNo.substring(1)
				: defMembership.business.country.phoneCode + formatPhoneNumber(data.number).replace(/\s/g, '').substring(1);
			const planObj = { id, name, business, groupId }
			const subscriberPhoneNo = data ? formatPhoneNumber(data.number).replace(/\s/g, '') : null
			const amount = (initialTier?.data?.policy === "TIER" && initialTier?.data?.cost !== null) ? initialTier?.data?.cost : null

			const inviteData = {
				plan: planObj,
				phoneNo: msisdn,
				msisdn: msisdn,
				requestedBy: defMembership,
				requestedOn: moment(requestedOn).format(),
				startDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : null,
				amount: data.amount ? data.amount : amount,
				deductions: data.deductions ? data.deductions : deductions
			};
			checkExclusivity(inviteData?.plan.id, inviteData.msisdn);
			setState({
				...state, checking: true, checkExclusivity: true, success: false,
				requesting: false, inviteData, exclusivity: null, phoneNo: subscriberPhoneNo
			});
		},
	});

	const toogleConfirmInviteSubscriber = () => {
		setState(state => { return { ...state, confirmVisible: !state.confirmVisible } })
	}

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const selectedData = (selectedNodeKey = state.selectedNodeKey) => {
		if (selectedNodeKey) {
			let selectedIndex = selectedNodeKey?.split("-")
			let sampleData = state.selectOptions[parseInt(selectedIndex[0])].children[parseInt(selectedIndex[1])]
			return sampleData
		}
		return null;
	}

	const planTierSelect = (e) => {
		formik.setFieldValue("plan_tier", selectedData(e.value).data)
		setState((state) => {
			return { ...state, selectedNodeKey: e.value }
		})
	}

	const foreverTxt = t("forever");
	const today = new Date();
	const invalidDates = [today];
	const policyType = initialTier ? initialTier?.data.policy : selectedData()?.data.policy

	const deductions = () => {
		let result;
		if (!initialTier && !selectedData()?.data) {
			result = ""
		}
		else if (initialTier) {
			if (initialTier?.data.deductions) {
				result = initialTier?.data.deductions
			}
			else {
				result = foreverTxt
			}
		}
		else if (!initialTier && selectedData().data) {
			if (selectedData().data.deductions) {
				result = selectedData().data.deductions
			}
			else {
				result = foreverTxt
			}
		}
		return result
	}

	//Toggle not Owner dialogue visibility
	const toggleVerifPendingDialogue = () => { setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible }; }); };

	//Toggle not Owner dialogue visibility
	const toggleNotOwnerDialogue = () => { setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible }; }); };

	const toggleNotSavedDialogue = () => { setState((state) => { return { ...state, notSavedVisible: !state.notSavedVisible } }) }

	const renderVerifFooter = (component) => {
		return (
			<div>
				<Button
					label={t("common.ok")}
					icon="pi pi-check"
					autoFocus
					style={{ backgroundColor: "#d0021b" }}
					onClick={
						component === "not_owner"
							? () => { toggleNotOwnerDialogue(); handleHide() }
							: () => { toggleVerifPendingDialogue(); handleHide() }
					}
				/>
			</div>
		);
	};

	const notSavedFooter = () => {
		return (
			<div>
				<Button style={{ backgroundColor: "#d0021b" }} label={t("common.ok")} icon="pi pi-check" autoFocus
					onClick={() => { toggleNotSavedDialogue(); onHide() }}
				/>
			</div>
		);
	}

	const renderFooter = () => {
		return (
			<div>
				<Button label={t("invitation.close")} icon="pi pi-times" onClick={() => setDisplayBasic(false)} autoFocus />
			</div>
		);
	}

	const amount = (data) => {
		let text;
		switch (data.policy) {
			case "TIER":
				if (data.flex) {
					text = t("invitation.flexible_amount")
				}
				else {
					text = `${defMembership.business.currency} ${data.cost?.toFixed(2)}`
				}
				break;
			case "SUBSCRIPTION":
				text = t("invitation.fixed_amount")
				break;
			default:
				text = t("invitation.generated_amount")
				break;
		}
		return text;
	}

	const handleHide = () => {
		onHide()
	}

	const toggleSetOpenDialog = () => {
		setOpenNotification(!openNotification);
	};

	const handlePhoneChange = (val) => {
		// Ensuring the phone number passed is always a string
		const phoneNumber = val || "";
		formik.setFieldValue("number", phoneNumber);
	};


	const selectedProducts = state.selectOptions.filter(item => item.children.length > 0)


	return (
		<>
			<Dialog
				header={t("idoc.id_verif_pending_header")}
				visible={state.verifPendingVisible}
				style={{ width: "360px" }}
				onHide={() => {
					toggleVerifPendingDialogue();
					onHide()

				}}
				footer={renderVerifFooter("verif_pending")}
			>
				<span>{t("idoc.id_verif_pending_text1")}</span> <span className="idoc-pend-txt">{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
			</Dialog>
			<Dialog
				header={t("idoc.not_owner_header")}
				visible={state.notOwnerVisible}
				style={{ width: "360px" }}
				onHide={() => { toggleNotOwnerDialogue(); onHide() }}
				footer={renderVerifFooter("not_owner")}
			>
				<p>{t("idoc.not_owner_text")}</p>
			</Dialog>
			<Dialog
				header={t("idoc.not_owner_header")} style={{ width: "360px" }} visible={state.notSavedVisible}
				onHide={() => { toggleNotSavedDialogue(); onHide() }} footer={notSavedFooter}
			>
				<p>{t("idoc.id_verif_pending_text")}</p>
			</Dialog>
			<Dialog
				header={t("idoc.not_owner_header")}
				visible={state.notOwnerVisible}
				style={{ width: "360px" }}
				onHide={toggleNotOwnerDialogue}
				footer={renderVerifFooter("not_owner")}
			>
				<p>{t("idoc.not_owner_text")}</p>
			</Dialog>
			<Dialog
				header={t("idoc.not_owner_header")} style={{ width: "360px" }} visible={state.notSavedVisible}
				onHide={() => { toggleNotSavedDialogue(); onHide() }} footer={notSavedFooter}
			>
				<div className="idoc-div" >
					<span className="not_saved" >{t("idoc.not_saved_viewer1")}</span>
					<p className="not_saved2">{t("idoc.not_saved_viewer2")}</p>

				</div>
			</Dialog>
			{(state.inviteData && state.confirmVisible) &&
				<ConfirmDialog visible={state.confirmVisible} onHide={hideInvite} className="confirm_close_btn" id="tier_dialog"
					message={<div style={{ marginTop: "0.6rem" }}>
						<span className="confirm_invite_detail detail">{t("invitation.confirm_invite_detail")}</span>&nbsp;
						<span className="phoneNo_invite">{state.inviteData.msisdn}</span>&nbsp;
						<span className="confirm_invite_detail">{t("invitation.subscribe_text")}</span>&nbsp;
						<span className="planName_invite">{state.inviteData.plan.name}</span>&nbsp;
						<span className="confirm_invite_detail">{"?"}</span>
					</div>}
					header={t("invitation.confirm_header")} accept={acceptInvite} reject={rejectInvite} />
			}

			{
				<ConfirmDialog visible={closeErrorDialog} onHide={() => closeDialog()} className="confirm_close_btn" id="tier_dialog"
					message={<div style={{ marginTop: "0.6rem" }}>
						{t("invitation.already_subscribed")}
					</div>}
					header={t("invitation.warning")}
					footer={<Button onClick={() => closeDialog()} className="p-mt-2" id="close-confirm-dialog"><i className="pi pi-times m-1"></i>{t("invitation.close")}</Button>}
				/>
			}

			{(state.exclusivity && state.desireVisible) &&
				<Dialog id="tier_dialog" className="tier_dialog" style={{ width: "360px" }} visible={state.desireVisible} header={<div>
					<span className="dialog_header" >{t("invitation.migrate_dialog_header")}{"?"}</span>
				</div>} onHide={() => hideExclusivity()}
					footer={<div style={{ marginBottom: "1rem" }}>
						<Button icon="pi pi-times" onClick={() => hideExclusivity()} label={t("invitation.cancel_move")} className="p-button-text hideBtn" />
						<Button onClick={() => acceptInvite()} loading={generating} label={t("invitation.migrate_subs_btn")} className="acceptBtn" />
					</div >} modal breakpoints={{ '960px': '75vw', '640px': '100vw' }} >
					<div className="first_blue_background">
						<span style={{ marginLeft: "0.1rem" }}>
							<span className="first_phone_no">{state.phoneNo || phoneNo}</span>
							<span className="first_span">{t("invitation.first_span")}</span>
						</span>
					</div>

					<div className="long_description">
						<p className="description_p">
							<span className="second_phoneNo">{state.phoneNo || phoneNo}</span>
							<span className="span_1">{t("invitation.span_1")}</span>
							<span className="span_2">{t("invitation.span_2")}</span>
							<span className="span_3">{t("invitation.span_3")}</span>
							<span className="red_texts">{t("invitation.current_plan")}</span>
							<span className="or_text">{t("invitation.or")} </span>
							<span className="red_texts">{t("invitation.span_4")}</span>
						</p>
					</div>

					<div><p className="current_tier_header">{t("invitation.current_subscription_text")}</p></div>
					<div className="blue_background">
						<span className="existing_name">{state.exclusivity.existing.name}</span>
						{state.exclusivity.existing.billingPeriod ?
							<span className="at_text1">{t("invitation.at")}</span> : null}
						<span className="amounts">{amount(state.exclusivity.existing)} {" "}
							{t(`product.${state.exclusivity.existing.billingPeriod.toLowerCase()}`)}
						</span>
					</div>
					<p className="p_hr_tag" />

					<div><p className="new_tier_header">{t("invitation.new_subscription_text")}</p></div>
					<div className="green_background">
						<span className="desired_name">{state.exclusivity.desired.name}</span>
						{state.exclusivity.existing.billingPeriod ?
							<span className="at_text2">{t("invitation.at")}</span> : null}
						<span className="amounts">{amount(state.exclusivity.desired)}{" "}
							{t(`product.${state.exclusivity.existing.billingPeriod.toLowerCase()}`)}
						</span>
					</div>
				</Dialog>}

			{(state.exclusivity && displayBasic) && <Dialog header={t("invitation.warning")} visible={displayBasic}
				id="tier_dialog" style={{ width: "360px" }} footer={renderFooter} onHide={() => setDisplayBasic(false)}>
				<p>{t("invitation.already_subscribed")} {state.exclusivity.desired.name}.</p>
			</Dialog>}

			<Toast ref={toastConfirm} onRemove={(message) => {
				if (message.severity === 'success') {
					resetForm()
				}
			}} />

			<Toast ref={toast} onRemove={(message) => {
				if (message.severity === 'success') {
					onHide()
					getSelfSignups(defMembership.business.id, { sort: state.sort, size: state.size, });
					setState({ ...state, send: false });
					setChecked(false);
					setSending(false)
					setState((state) => ({ ...state, desireVisible: false, visible: false }));
					setToggleDeductionNumber(false);
					getBusiness(defMembership.business.id)
					resetForm();
					callbackFunctions()
				}
			}} />

			<div className="p-grid p-nogutter text-justify flex justify-between">
				<Dialog header={header ? header : t("invitation.invite")} visible={state.visible} className="tier_dialog" id="tier_dialog"
					onHide={() => {
						handleHide();
						setToggleDeductionNumber(false);
						formik.setFieldValue("number", "");
						setState(() => ({ ...state, visible: false }));
						resetForm();
					}}>
					{(!planGroupsGraphedActive || !business || !defMembership) ? <Loading /> :
						<>
							<div style={{ padding: "1.5rem" }}>
								<div className="p-fluid">
									<div className="title flex"><p className="labels">{t("invitation.subscription_plan")}</p><p className="isterik">*</p></div>
									<div className="invite" style={{ marginBottom: "0.5rem" }}>
										{!initialTier ?
											<TreeSelect id="plan_tier"
												name="plan_tier"
												value={!initialTier ? state.selectedNodeKey : initialTier.key}
												options={selectedProducts}
												onChange={(e) => planTierSelect(e)}
												filter
												disabled={initialTier}
												placeholder={t("invitation.plan_select")}
												style={{ height: "3rem" }}
												className={classNames({
													"p-invalid": isFormFieldValid("plan_tier"),
												})}
											>
											</TreeSelect>
											: <span>
												<InputText
													id="tier"
													name="tier"
													style={{ height: "3rem" }}
													value={initialTier.label}
													disabled
												/>
											</span>}
										<label
											htmlFor="plan_tier"
											className={classNames({
												"p-error": isFormFieldValid("plan_tier"),
											})}
										></label>
									</div>
									{getFormErrorMessage("plan_tier")}
								</div>
								<form onSubmit={formik.handleSubmit} className="p-fluid flex flex-column mt-3" >
									<div className="title flex"><p className="labels">{t("invitation.subscriber_number")}</p><p className="isterik">*</p></div>
									<div className="invite-number">
										<span className="invite-phone" style={{ height: "3rem" }}>
											<PhoneInput
												name="number"
												placeholder={t("invitation.number")}
												addInternationalOption={false}
												value={formik.values.number || ""}
												limitMaxLength
												disabled={!!phoneNo}
												international={false}
												style={{ height: "3rem" }}
												countryCallingCodeEditable={false}
												onChange={handlePhoneChange}
												title="phoneNumber"
												defaultCountry="GH"
												className={classNames({
													"p-invalid": isFormFieldValid("number"),
												})}
											/>
										</span>
										{getFormErrorMessage("number")}
									</div>
									{
										(policyType !== "SUBSCRIPTION") ? (<span> { } </span>) :
											(<div>
												<div className="title" style={{ margin: "0.5rem 0 0.3rem 0" }}>{t("amount")} <span className="isterik">*</span></div>
												<div style={{ marginBottom: "0.5rem" }}>
													<InputText
														id="amount amt"
														name="amount"
														style={{ height: "3rem" }}
														value={formik.values.amount}
														onChange={formik.handleChange}
														placeholder={`${t("invitation.amount")} (${defMembership.business.currency})`}
													/>
												</div>
												{getFormErrorMessage("amount")}
											</div>)
									}
									<br />
									<div className="title flex"><p className="labels">{t("invitation.subscriber_start_date")}</p><p className="isterik">*</p></div>

									<div style={{ marginBottom: "1.3rem" }}>
										<div className="date-content">
											<div style={{ marginBottom: "0.3rem" }}>
												<div className="calen-div">
													<Calendar
														id="startDate invite-calender"
														name="startDate"
														value={formik.values.startDate}
														/* Disable today's date  */
														disabledDates={invalidDates}
														/* Setting the minimum date to today's date so any date less than today's date can't be selected */
														minDate={today}
														/* Creating a read only input field to prevent entering date manually with keyboard. */
														readOnlyInput
														inputMode=""
														dateFormat={business?.country?.jsShortDateFormat}
														onChange={formik.handleChange} showIcon
														placeholder={t("invitation.date_select")}
														className="p-datepicker-header invite-calender datepicker"
														disabled={checked}
													/>
												</div>
												<label
													htmlFor="startDate"
													className={classNames({
														"p-error": isFormFieldValid("startDate"),
													})}
												></label>
											</div>
											<div className="error-msg">{getFormErrorMessage("startDate")}</div>
										</div>
										<div className="flex">
											<Checkbox
												id="check_box"
												className="check"
												inputId="binary"
												checked={checked}
												onChange={() => setChecked(!checked)}
											/>
											<div className="checkedbox_div_label">{!checked ? <label className="unchecked_label">{t("invitation.date_checkbox")}</label> :
												<label id="checkbox_title" htmlFor="binary">{t("invitation.date_checkbox")}</label>}
											</div>
										</div>
									</div>
									<span>{planGroupsGraphedActive.groups.plans?.deductions}</span>
									<div>
										<div className="labels"><p className="deduction_title">{t("plan.deduction_detail")}</p></div>
										<InputText
											id="deductions invite-deduc"
											name="deductions"
											style={{ height: "3rem" }}
											value={toggleDeductionNumber === true ? formik.values.deductions : ""}
											onChange={formik.handleChange}
											placeholder={toggleDeductionNumber === true ? "" : deductions()}
											disabled={!toggleDeductionNumber}
											className={classNames({
												"p-invalid": isFormFieldValid("deductions"),
											})}
											keyfilter="int"
										/>
										<div className="error-deduct">{getFormErrorMessage("deductions")}</div>
										<div className="main_div_input_switch">
											<div>
												<InputSwitch
													id="toggleDeductionNumber"
													name="toggleDeductionNumber"
													checked={toggleDeductionNumber}
													onChange={(e) => { setToggleDeductionNumber(!toggleDeductionNumber) }}
												/>
											</div>
											<div>
												{toggleDeductionNumber ? <span id="toggled">{t("invitation.override_text")}</span> :
													<span id="untoggled">{t("invitation.override_text")}</span>}
											</div>
										</div>
									</div>
									<Button
										type="submit" loading={generating || fetchingExclusivity} disabled={state.requesting || generating}
										className="p-mt-2" id="send_invite_btn"
									>{t("invitation.send_invite")}</Button>
								</form>
							</div>
						</>
					}
				</Dialog>
			</div>
		</>
	)
}

export default (InviteSubscriber);
import React from 'react'
import { Card } from 'primereact/card';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import { useTranslation } from "react-i18next";
import { AutoComplete } from "primereact/autocomplete";
import "../../Styles/pages/transactions.scss";
import "../../Styles/pages/payments.scss";

export const ActionBar = ({
    selectedType, setSelectedType, dropdownItems, selectedOption, updateFirstState,
    summaryOptions, setSelectedOption, selectedCustomer, filteredCustomer, searchCustomers, periods, period, setDates, setPeriod, setPage,
    setSelectedCustomer, setSelectedBcId, setCleared, filtered, search, setFiltered, dates, placeholder, location, displayCustomer, setDisplayCustomer,
    onClick, total, setTypeChanged }) => {

    const { t } = useTranslation("common");
    const handleClear = () => {
        setSelectedCustomer(null)
        setDisplayCustomer(null)
        setSelectedBcId(null)
    };

    return (
        <div>
            <Card id="payment-action-bar" style={{ marginBottom: "3rem", width: "auto" }}>
                <div className='col-12'>
                    <div className="p-grid p-fluid main-div">
                        <>
                            {
                                <div className="p-col-12 payment-count" >
                                    <span> <span className='countNumber'>{total ? total : 0}</span> <span className='countText'>{selectedType.code === "CP"
                                        ? total <= 1 ? "Collected Payment" : "Collected Payments" : selectedType.code === "MP" ?
                                            total <= 1 ? "Missed Payment" : "Missed Payments" : total <= 1 ? "External payment" : "External Payments"}</span></span>
                                </div>
                            }
                            {
                                <div className="p-col-12 p-md-3 filters" >
                                    <div className="p-inputgroup ">
                                        <Dropdown
                                            value={selectedType}
                                            onChange={(e) => {
                                                setPage(0);
                                                updateFirstState()
                                                setSelectedType(e.value);
                                                setTypeChanged(true)
                                            }}
                                            options={dropdownItems}
                                            optionLabel="name"
                                            className="w-full  activity-dropdown report-plan-customer-dropdown"
                                            id='report-plan-customer-dropdown' />
                                    </div>
                                </div>
                            }
                            <div className="p-col-12 p-md-3 filters autocomplete-container" style={{ display: "flex", alignItems: "center" }}>
                                <div className="p-inputgroup" style={{ width: "18rem" }}>
                                    <AutoComplete field={selectedType.code === "CP" ? `busCustomerName` : `customerName`} placeholder={t("payments.search_by")}
                                        value={selectedCustomer? selectedCustomer : displayCustomer}
                                        onSelect={(e) => setSelectedBcId(e.value)}
                                        loadingIcon={false}
                                        // Auto Suggestions might come in handy later!!

                                        // suggestions={selectedCustomer?.length >= 3 && filteredCustomer}
                                        // suggestions={filteredCustomer}
                                        // suggestions={filtered}
                                        // completeMethod={searchCustomers}
                                        // completeMethod={search}
                                        onChange={(e) => { setSelectedCustomer(e.value);  setSelectedBcId(null) }} />
                                    {(selectedCustomer || displayCustomer ) && <i className="pi pi-times clear-icon" style={{ fontSize: '1rem', color: "red" }} onClick={() => { handleClear() }}></i>}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3 filters">
                                <div className="p-inputgroup" style={{ width: "auto" }}>
                                    <Dropdown
                                        value={selectedOption}
                                        options={summaryOptions}
                                        optionLabel="name"
                                        placeholder={placeholder}
                                        filter
                                        showClear
                                        onChange={(e) =>
                                            setSelectedOption(e.value)}
                                        id={location.state?.id && 'activeDropdown'}
                                        className="w-full activity-dropdown" />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-3 filters" >
                                <div className="p-inputgroup">
                                    <Dropdown
                                        className="activity-dropdown"
                                        value={period}
                                        id="time"
                                        options={periods}
                                        onChange={(e) => {
                                            setPeriod(e.value)
                                            if (e.value.name === "Lifetime") {
                                                setDates(null)
                                            }
                                        }}
                                        optionLabel="name"
                                        disabled={dates !== null}
                                    />
                                </div>
                            </div>
                            {period.code === "CUSTOM" && <div className="p-col-12 p-md-4 input-div" style={{ width: "16.5rem" }}>
                                <div className='report_calender'>
                                    <Calendar className="p-datepicker-header report-calender datepicker"
                                        id='report-calender'
                                        value={dates}
                                        showIcon
                                        showButtonBar
                                        onClearButtonClick={() => { setCleared(true) }}
                                        dateFormat="dd M yy"
                                        maxDate={new Date()}
                                        onChange={(e) => {
                                            setDates(e.value)
                                        }} selectionMode="range"
                                        readOnlyInput
                                        placeholder={t("Choose start and end dates")}
                                    />
                                </div>
                            </div>}
                        </>
                    </div >
                </div>
            </Card>
        </div>
    )
}

import React, { useEffect } from "react";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import keycloak from "./keycloak";

const TokenMonitor = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) return;

    // Check token validity every minute
    const checkInterval = setInterval(() => {
      const timeToExpire = keycloak.tokenParsed.exp - (Date.now() / 1000);
      
      
      if (timeToExpire < 30) {
        keycloak.updateToken(30).catch(() => {
          keycloak.logout();
        });
      }
      
      // If refresh token is expired, force logout
      if (keycloak.refreshTokenParsed.exp < Date.now() / 1000) {
        keycloak.logout();
      }
    }, 60000); 

    return () => clearInterval(checkInterval);
  }, [keycloak]);

  return null;
};

const KeycloakProviderWrapper = ({ children }) => (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: "check-sso",
      checkLoginIframe: false,
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      pkceMethod: "S256",
      promiseType: "native",
      enableLogging: true
    }}
  >
    <TokenMonitor />
    {children}
  </ReactKeycloakProvider>
);

export default KeycloakProviderWrapper;
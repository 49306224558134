import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useTranslation, Trans } from "react-i18next";
import Loading from "../../Components/Loading";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import BusinessCustomersActions from "../../Redux/transition/customers/business-customer.reducer";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../Components/CustomDataTable";

function Import() {
    const { t } = useTranslation("common");

    const fileUploadRef = useRef(null);
    const toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});
    const [state, setState] = useState({
        enterpriseCustomers: null,
        importedCustomers: [],
        uploadedCustomers: null,
        entries: null,
        file: {},
        first: 0,
        count: null,
        page: 0,
        sort: "importedOn,desc",
        size: 15,
        loading: true,
        loadingId: null,
        uploading: false,
        applying: false,
        selectedId: null,
        openDailog: false,
        appliedOn: null,
    });

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessCustomers = useSelector((state) => state.businessCustomers.businessCustomers);
    const uploadedCustomers = useSelector((state) => state.businessCustomers.uploadCustomer);
    const uploadedCustomersError = useSelector((state) => state.businessCustomers.uploadError);
    const uploadingCustomers = useSelector((state) => state.businessCustomers.uploading);
    const importedCustomers = useSelector((state) => state.businessCustomers.importedCustomer);
    const importedCustomersCount = useSelector((state) => state.businessCustomers.importedCustomerCount);
    const entries = useSelector((state) => state.businessCustomers.entries);
    const fetchingEntries = useSelector((state) => state.businessCustomers.fetchingEntries);
    const applying = useSelector((state) => state.businessCustomers.applying);
    const applySuccess = useSelector((state) => state.businessCustomers.appliedImport);
    const applyError = useSelector((state) => state.businessCustomers.appliedImportError);
    const fetchingImportedCustomers = useSelector((state) => state.businessCustomers.fetchingImportedCustomers);

    const dispatch = useDispatch();
    const getMembership = useCallback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);
    const getBusinessCustomers = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerAllRequest(businessId)), [dispatch]);
    const getImportedCustomers = useCallback((businessId, options) => dispatch(BusinessCustomersActions.businessCustomerImportRequest(businessId, options)), [dispatch]);
    const getImportedCustomersCount = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerImportCountRequest(businessId)), [dispatch]);
    const getEntries = useCallback((importId) => dispatch(BusinessCustomersActions.businessCustomerEntryRequest(importId)), [dispatch]);
    const applyImport = useCallback((id) => dispatch(BusinessCustomersActions.businessCustomerApplyRequest(id)), [dispatch]);

    const fetchAllImportedCustomers = useCallback(
        (page) => {
            getImportedCustomers(defMembership.business.id, { page, size: state.size, sort: state.sort });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [defMembership.business.id, getImportedCustomers, state.size, state.sort]
    );

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getImportedCustomersCount(defMembership.business.id);
            getBusinessCustomers(defMembership.business.id);
            fetchAllImportedCustomers(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (businessCustomers) {
            setState((state) => ({ ...state, enterpriseCustomers: businessCustomers }));
        }
        if (importedCustomers) {
            setState((state) => ({ ...state, importedCustomers: importedCustomers }));
        }
        if (importedCustomersCount) {
            setState((state) => ({ ...state, count: importedCustomersCount }));
        }
        if (uploadedCustomers) {
            setState((state) => ({ ...state, uploadedCustomers: uploadedCustomers }));
        }
        if (entries) {
            setState((state) => ({ ...state, entries: entries }));
        }
    }, [businessCustomers, importedCustomers, uploadedCustomers, entries, importedCustomersCount]);

    useEffect(() => {
        if (!uploadingCustomers && uploadedCustomers && state.uploading) {
            setDisplayBasic(true);
            setState((state) => ({ ...state, uploading: false }));
            fileUploadRef.current = null;
        }

        if (!uploadingCustomers && uploadedCustomersError?.message === "Cannot process CSV file supplied" && state.uploading) {
            showError(t("business_customers.user_upload_error"), "user");
        } else if (!uploadingCustomers && uploadedCustomersError && state.uploading) {
            showError(t("business_customers.upload_error"));
        }

        if (!applying && applySuccess !== null && state.applying) {
            showSuccess();
        }
        if (!applying && applyError !== null && state.applying) {
            showError(t("business_customers.apply_error"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadingCustomers, uploadedCustomers, state.uploading, applying, state.applying, applySuccess, applyError, uploadedCustomersError]);

    if (fetchingImportedCustomers) {
        return <Loading />;
    }

    const showSuccess = () => {
        toast.current.show({ severity: "success", summary: t("business_customers.success_summary"), detail: t("business_customers.apply_success"), life: 3000 });
    };

    const showError = (message, user) => {
        toast.current.show({ severity: "error", user: user, summary: t("business_customers.error_summary"), detail: message, life: 3000 });
    };

    const handleSelect = (data) => {
        getEntries(data.id);
        setDisplayBasic(true);
        setState((state) => ({ ...state, openDailog: true, appliedOn: data.appliedOn, selectedId: data.id }));
    };

    const applyId = state.openDailog ? state.selectedId : state.uploadedCustomers?.id;


    const handleApply = (customerId) => {
        setState(state => ({ ...state, loadingId: customerId }))
        if (state.uploadedCustomers?.appliedOn === null || state.appliedOn === null) {
            setLoadingStates(prevState => ({
                ...prevState,
                [customerId]: true
            }))
            applyImport(applyId);
            setState((state) => ({ ...state, applying: true, openDailog: false }));
        } else {
            setDisplayBasic(false);
            setState((state) => ({ ...state, openDailog: true }));
        }
    };

    const onHide = () => {
        setDisplayBasic(false);
        setState((state) => ({ ...state, openDailog: false }));
    };

    const boolenConvert = (data) => {
        if (data) {
            return t("common.true");
        } else {
            return t("common.false");
        }
    };

    const onPageChange = (e) => {
        const { page, first, rows } = e;
        fetchAllImportedCustomers(page, rows);
        setState((prevState) => ({
            ...prevState,
            first,
            size: rows
        }));
    };

    const entryData = state.openDailog ? state.entries : state.uploadedCustomers?.entries;
    let columnsEntries = [
        { field: "firstName", header: t("common.first_name"), },
        { field: "lastName", header: t("common.last_name"), },
        { field: "phoneNo", header: t("common.phone_no"), },
        { field: "customerNum", header: t("business_customers.customer_num"), },
        {
            field: "phoneNoValid",
            header: t("business_customers.phone_valid"),
            body: (data) => (boolenConvert(data.phoneNoValid)),
            sort: true,
        }, {
            field: "imported",
            header: t("business_customers.imported"),
            body: (data) => {
                boolenConvert(data.imported);
            },
            sort: true,
        },
    ]
    const customerImportIdBody = (data) => {
        const successfulImport = data.entryCount === data.importedCount;
        return <div className="customer-import-id">
            <div className={`${data.appliedOn === null ? "import-black"
                : successfulImport ? "import-green"
                    : !successfulImport ? "import-yellow"
                        : applyError ? "import-red"
                            : null}`} >

                <div className="flex">
                    <p className="mb-0">{t("business_customers.import_on")}</p>
                    <p className="ml-1 mb-0">{moment(data.importedOn).format("MMMM Do YYYY")}</p>
                </div>
            </div>
            <p className="import-text hov" onClick={() => handleSelect(data)}>{data.externalId}</p>
        </div>
    }

    const customerImportDetailsBody = (data) => {
        return <div className="customer-import-id">
            <p className="mb-0">{moment(data.importedOn).format("MMMM D, YYYY")}</p>
            <p>{moment(data.importedOn).format("h:mm A")}</p>
        </div>
    }

    const customerAddedOnBodyTemplate = (data) => {
        const successfulImport = data.entryCount === data.importedCount;

        return <div >
            {data.appliedOn === null ? <div className="customer-import-id">
                <Button
                    className="import_button"
                    loading={loadingStates[data.id]}
                    disabled={!entryData}
                    onClick={() => { handleApply(data.id) }}
                >{t("business_customers.add_customer")}</Button>
            </div>
                :
                <div className={`grid ${data.appliedOn === null ? "import-black-i"
                    : successfulImport ? "import-green-i"
                        : !successfulImport ? "import-yellow-i"
                            : applyError ? "import-red-i"
                                : null} `}>
                    <div className="col-3 align-content-center text-left pr-0 pl-0">
                        <i className={`pi icon-style ${applyError ? "pi-times-circle" : "pi-check-circle"} `}></i>
                    </div>
                    <div className="col-9 customer-import-id pr-0 pl-0">
                        <p className="mb-0" style={{ color: "#008DD5" }}><Trans i18nKey="business_customers.entries" values={{ val: data.entryCount }} /></p>
                        <p className="mb-0 import-text">{successfulImport ? t("business_customers.successful")
                            : !successfulImport ? t("business_customers.completed-errors")
                                : applyError ? t("business_customers.failed")
                                    : null}</p>
                        <p>{data.appliedOn ? moment(data.appliedOn).format("MMMM Do YYYY") : "--"}</p>
                    </div>
                </div>}
        </div>
    }
    const customerImportCountBodyTemplate = (data) => {
        const skippedDuplicatesCount = data.entryCount - data.importedCount;
        const importedBy = defMembership.user?.name;
        return <div className="customer-import-id">
            {data.importedCount === data.entryCount ? <>
                <div className="flex font-bold import">
                    <p>{<Trans i18nKey="business_customers.records" values={{ val: data.importedCount }} components={{ 1: <strong style={{ color: '#4caf50' }} /> }} />}</p>
                </div>
                <div className="flex">
                    <p className="import-text font-bold mb-0">{t("business_customers.applied-by")}</p>
                    <p className="import-text mb-0 ml-1">{importedBy}</p>
                </div>
            </> : data.importedCount && data.importedCount !== data.entryCount ? <>
                <div className="flex font-bold">
                    <p>{<Trans i18nKey="business_customers.records" values={{ val: data.importedCount }} components={{ 1: <strong style={{ color: '#4caf50' }} /> }} />}</p>
                </div>
                <p className="import-text mb-0">{<Trans i18nKey="business_customers.skipped_duplicates" values={{ val: skippedDuplicatesCount }} components={{ 1: <strong style={{ color: "#d0021b" }} /> }} />}</p>
                <div className="flex">
                    <p className="import-text font-bold mb-0">{t("business_customers.applied-by")}</p>
                    <p className="import-text mb-0 ml-1">{importedBy}</p>
                </div>
            </>

                : applyError ? <>
                    <p className="import-text font-bold">{t("business_customers.applied-by")}{importedBy}</p>
                    <p className="import-text">{t("business_customers.contact")}</p>
                </> : data.importedCount === 0 ? <div>
                    <div className="flex font-bold">
                        <p>{<Trans i18nKey="business_customers.records" values={{ val: data.importedCount }} components={{ 1: <strong style={{ color: '#d0021b' }} /> }} />}</p>
                    </div>
                    <p className="import-text mb-0">{<Trans i18nKey="business_customers.skipped_duplicates" values={{ val: skippedDuplicatesCount }} components={{ 1: <strong /> }} />}</p>
                </div> : <div>
                    --
                </div>
            }
        </div>
    }

    let columnsImports = [
        { field: "externalId", header: t("business_customers.id"), body: customerImportIdBody, alignHeader: "start" },
        {
            field: "importedOn", header: t("business_customers.imported_on"), body: customerImportDetailsBody, alignHeader: "start"
        },
        {
            field: "appliedOn", header: t("business_customers.applied_on"), body: customerAddedOnBodyTemplate, alignHeader: "start"
        },
        { field: "importedCount", header: t("business_customers.import_count"), body: customerImportCountBodyTemplate, alignHeader: "start" }

    ]

    return (
        <>
            <Toast
                ref={toast}
                onRemove={(message) => {
                    setLoadingStates(prevState => ({
                        ...prevState,
                        [state.loadingId]: false
                    }));
                    if (message.severity === "success") {
                        setDisplayBasic(false);
                        setState((state) => ({ ...state, applying: false }));
                        getImportedCustomers(defMembership.business.id, { page: state.page, size: state.size, sort: state.sort });
                    }
                }}
            />
            <Dialog header={t("business_customers.import_entries")} visible={displayBasic} style={{ width: "50vw" }} onHide={() => onHide("displayBasic")}>
                {entryData && !fetchingEntries ? (
                    <CustomDataTable columns={columnsEntries} value={entryData} paginator={true} rows={state.size}>
                    </CustomDataTable>
                ) : (
                    <>{<Loading />}</>
                )}
            </Dialog>

            {state.importedCustomers && (
                <div>
                    <CustomDataTable
                        value={state.importedCustomers}
                        sortField="importedOn"
                        sortOrder={-1}
                        paginator={false}
                        selectionMode="single"
                        columns={columnsImports}
                    >
                    </CustomDataTable>
                    <Paginator rows={state.size} totalRecords={state.count} first={state.first} onPageChange={onPageChange}></Paginator>
                </div>
            )}
        </>
    );
}
export default Import;
